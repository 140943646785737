import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import moment from "moment";
import React, { useEffect } from "react";
import TableHead from '@mui/material/TableHead';
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentHistoryDetails as getPaymentHistoryDetailsAPI } from "services/api/paymenthistory-api-service";
import { ShimmerTable } from 'shimmer-effects-react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary
}));

const PaymentsHistory = ({subscription_id, policy_id, platform_name, incident_date}) => {

  const dispatch = useDispatch();

  const paymentHistoryDetails = useSelector(
    (state) => state.paymentHistorySlice.paymentHistory
  );
  const paymentHistoryLoading = useSelector(
    (state) => state.paymentHistorySlice.loading
  );
  const paymentHistoryError = useSelector(
    (state) => state.paymentHistorySlice.error
  );

  // console.log("paymentHistoryDetails", paymentHistoryDetails);
  // console.log("paymentHistoryLoading", paymentHistoryLoading);
  // console.log("paymentHistoryError", paymentHistoryError);

  useEffect(() => {
    if (subscription_id !== null && policy_id !== null && platform_name !== null && incident_date !== null) {
      const date_of_incident = moment(incident_date).format("YYYY-MM-DD");
      // GET PAYMENT HISTORY DETAILS
      dispatch(getPaymentHistoryDetailsAPI({ subscription_id, policy_id, platform_name, date_of_incident }));
    }
  }, [subscription_id, policy_id, platform_name, incident_date]);

  return (
    <>{
      paymentHistoryLoading && <ShimmerTable
          loading={true}
          mode="light"
          row={1}
          col={5}
          border={1}
          borderColor={"#cbd5e1"}
          rounded={1}
          rowGap={16}
          colPadding={[10, 5, 10, 5]}
        >
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <tbody>
              {[...Array(5)].map((_, rowIndex) => (
                <tr key={rowIndex} style={{ height: "40px" }}>
                  {[...Array(1)].map((_, colIndex) => (
                    <td
                      key={colIndex}
                      style={{
                        border: "1px solid #cbd5e1",
                        borderRadius: "0.25em",
                        padding: "10px 5px",
                        textAlign: "center",
                      }}
                    >
                      Col {colIndex}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </ShimmerTable>
    }
     {(!paymentHistoryLoading && paymentHistoryDetails) && <TableContainer component={Paper}>
      <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
        <StyledTableHeader>
          <TableRow>
            <StyledTableCell>Subscription Id</StyledTableCell>
            <StyledTableCell align="left">Deduction Month</StyledTableCell>
            <StyledTableCell align="left">Deduction Year</StyledTableCell>
            <StyledTableCell align="left">Deduction Amount</StyledTableCell>
            <StyledTableCell align="left">Deduction Status</StyledTableCell>
          </TableRow>
        </StyledTableHeader>
        <TableBody>
          {paymentHistoryDetails.map((paymentHistoryItem) => (
            <StyledTableRow key={paymentHistoryItem.subscription_id}>
              <StyledTableCell component="th" scope="row">
                {paymentHistoryItem.subscription_id ? paymentHistoryItem.subscription_id : "NA"}
              </StyledTableCell>
              <StyledTableCell align="left">{paymentHistoryItem.deduction_month_name ? paymentHistoryItem.deduction_month_name : "NA"}</StyledTableCell>
              <StyledTableCell align="left">{paymentHistoryItem.deduction_year ? paymentHistoryItem.deduction_year : "NA"}</StyledTableCell>
              <StyledTableCell align="left">{paymentHistoryItem.deducted_amount ? paymentHistoryItem.deducted_amount : "NA"}</StyledTableCell>
              <StyledTableCell align="left">{paymentHistoryItem.deduction_status ? paymentHistoryItem.deduction_status : "NA"}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    </>
  );
}

export default PaymentsHistory;
