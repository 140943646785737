import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoutes = () => {
  const user = useSelector((store) => store.authSlice.user);
  const location = useLocation();

  // console.log("location.pathname >>>>>>>>>>>>>>>>>>>");
  // console.log(location.pathname);

  const fullUrl = window.location.href; // Full URL
  const baseUrl = window.location.origin; // Base URL (e.g., http://example.com)
  const path = fullUrl.replace(baseUrl, '');
  
  return user && Object.keys(user).length !== 0 ? (
    <Outlet />
  ) : (
    /* <Navigate to={location.pathname !== "/" ? `/signin?redirect=${location.pathname}` : '/signin'}  /> */
    <Navigate to={path !== "/" ? `/signin?redirect=${encodeURIComponent(fullUrl)}` : '/signin'}  />
  );
};

export default ProtectedRoutes;
