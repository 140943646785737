import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box, Card, Container } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchLoginDetails } from "services/api/auth-api-service";
import SnackbarMessage from "ui/components/utils/alert-message.component";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import debuglog from "utils/debuglog";
import { ValidatorService } from "utils/validator";

const VALIDATOR = {
  username: (value) => {
    return ValidatorService.min(value, 3) || ValidatorService.max(value, 20);
  },
  password: (value) => {
    return ValidatorService.min(value, 3);
  },
};

const SignInForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [backdropOpen, setBackdropOpen] = useState(false);

  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
  });

  const getProtocolHostName = window.location.origin;
  console.log("getProtocolHostName ", getProtocolHostName);

  const redirectUrl = `${process.env.REACT_APP_AUTH_URL}/forget-password?redirect_url=https://${window.location.hostname}`;

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({ severity: "info", message: "" });

  const [formErrors, setFormErrors] = useState({
    username: formValues.username ? undefined : true,
    password: formValues.password ? undefined : true,
  });

  const onCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  debuglog(formErrors);

  // Fetch data from API
  const fetchLoginData = async (username, password) => {
    try {
      console.log("fetchLoginData: formData", username, password);
      var data = { username, password };
      const response = await dispatch(fetchLoginDetails(data));
      const loginResponse = response.payload;

      if (loginResponse.status === 200) {
        setBackdropOpen(false);
        // dispatch(setUser(loginResponse.data));
        setFormValues({ username: "", password: "" });
        setAlertData((prevState) => ({
          ...prevState,
          severity: "success",
          message: "Login Successful",
        }));
        setAlertOpen(true);
        setTimeout(() => {
          const redirectPath = searchParams.get("redirect") || "/";
          window.location.href = decodeURIComponent(redirectPath);
        }, 100);
      } else {
        setBackdropOpen(false);
        setAlertData((prevState) => ({
          ...prevState,
          severity: "error",
          message: "Login Failed. Please check your credentials",
        }));
        setAlertOpen(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAlertData((prevState) => ({
        ...prevState,
        severity: "error",
        message: "Login Failed. Please check your credentials",
      }));
      setAlertOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { username, password } = formValues;
    try {
      setBackdropOpen(true);
      fetchLoginData(username, password);
    } catch (error) {
      setBackdropOpen(false);
      console.log(error);
      setAlertData((prevState) => ({
        ...prevState,
        severity: "error",
        message: "Login Failed. Please check your credentials",
      }));
      setAlertOpen(true);
    }
  };

  const validate = (fieldName, fieldValue) => {
    setFormErrors({
      ...formErrors,
      [fieldName]: VALIDATOR[fieldName](fieldValue),
    });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({ ...formValues, [name]: value });
    validate(name, value);
  };

  return (
    <Box sx={{ minWidth: 275, pt: 8 }}>
      {/* Loader */}
      <CommonBackDrop backdropOpen={backdropOpen} />
      <Card variant="outlined">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    // href="https://ghauth.milvik.io/forget-password?redirect_url=https://ghclaims.milvik.io"
                    href={redirectUrl}
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {alertOpen && (
            <SnackbarMessage
              severity={alertData.severity}
              message={alertData.message}
              onOpen={alertOpen}
              onCloseHandler={onCloseHandler}
              vertical="top"
              horizontal="center"
            />
          )}
        </Container>
      </Card>
    </Box>
  );
};

export default SignInForm;
