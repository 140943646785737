import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { NavigateBefore, Save, Send, Edit } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parse } from "date-fns";
import {
  fetchCommunicationTemplate,
  renderCommunicationTemplate,
  sendCommunicationTemplate,
  submitCommunicationDetails,
} from "services/api/communication-api-service";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import { getClaimDetailsAPI } from "services/api/getclaimdetails-api-service";
import { useParams } from "react-router-dom";
import { MESSAGES } from "../../../components/utils/message-constants";
import Spacer from "ui/components/utils/spacer";
import AgentRemarks from "./agent-remarks";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import {
  CLAIM_COMMUNICATIONON_MESSAGE_ACCESS,
  CLAIM_COMMUNICATIONON_PAGE_ACCESS,
} from "ui/components/utils/role-constants";
import PageAccessDenied from "ui/components/page-access-denied/page-access-denied.component";
import ClaimNotReached from "ui/components/utils/claim-not-reached.component";
import { validateEmail } from "ui/components/utils/utils";
import CommunicationDetailsCard from "ui/components/utils/communication-detail-card.component";

const ClaimsCommunication = ({
  onNextButtonClick,
  displayAlert,
  roleAndPrivilageMap,
  showLoadingBackdrop,
}) => {
  const dispatch = useDispatch();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [customerId, setCustomerId] = useState("");

  const dateFormat = "yyyy-MM-dd";
  const [templateName, setTemplateName] = useState("");
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("");
  const [displayMessage, setDisplayMessage] = useState("");
  const [currentStage, setCurrentStage] = useState(null);

  const [decision, setDecision] = useState("");
  const [approvedPayout, setApprovedPayout] = useState("");

  const [communicationDate, setCommunicationDate] = useState(null);
  const [agentRemarks, setAgentRemarks] = useState("");

  const [smsTemplateList, setSmsTemplateList] = useState(null);
  const [whatsAppTemplateList, setwhatsAppTemplateList] = useState(null);
  const [emailTemplateList, setEmailTemplateList] = useState(null);

  const [contactError, setContactError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [displayMessageError, setDisplayMessageError] = useState(false);
  const [decisionDateError, setDecisionDateError] = useState(false);
  const [decisionOutcomeError, setDecisionOutcomeError] = useState(false);

  // META DATA
  const [metaDataList, setMetaDataList] = useState([]);
  const [defaultMetaDataList, setDefaultMetaDataList] = useState([]);
  const allMetaData = useSelector((state) => state.configSlice.metadata);
  // Filter for communication stage
  const filteredMetaData = allMetaData?.filter((stages) =>
    stages.filter.some(
      (stage) => stage === MESSAGES.CLAIM_STAGE_COMMUNICATION_ID
    )
  );
  console.log("metaData", allMetaData, filteredMetaData);

  const [communicationOutcome, setCommunicationOutCome] = useState({
    id: "",
    name: "",
  });
  const [commChannel, setCommChannel] = useState({ id: "", name: "" });

  const [errors, setErrors] = useState({
    templateName: false,
    message: false,
    contact: false,
  });

  const [isCurrentStageInCommunication, setIsCurrentStageInCommunication] =
    useState(false);

  const [communicationDataExists, setCommunicationDataExists] = useState(false);
  const [
    editCommunicationDetailsInProgress,
    setEditCommunicationDetailsInProgress,
  ] = useState(false);

  const communicationStageAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIM_COMMUNICATIONON_PAGE_ACCESS
  );

  const communicationMessageAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIM_COMMUNICATIONON_MESSAGE_ACCESS
  );

  const [userHasViewAccess, setUserHasViewAccess] = useState(false);
  const [userHasListAccess, setUserHasListAccess] = useState(false);
  const [userHasEditAccess, setUserHasEditAccess] = useState(false);
  const [userHasWriteAccess, setUserHasWriteAccess] = useState(false);
  const [userHasImportAccess, setUserHasImportAccess] = useState(false);
  const [userHasCommunicationAccess, setUserHasCommunicationAccess] =
    useState(false);

  const params = useParams();
  const claimId = params.claimId;

  // CLAIM STATE
  const claimDetails = useSelector(
    (state) => state.claimDetailsSlice.claimDetails
  );
  const claimDetailsLoading = useSelector(
    (state) => state.claimDetailsSlice.loading
  );
  const templateLoading = useSelector(
    (state) => state.communicationSlice.loading
  );
  const claimDetailsError = useSelector(
    (state) => state.claimDetailsSlice.error
  );

  // CONFIG STATE
  const communicationMode = useSelector(
    (state) => state.configSlice.communicationMode
  );
  const decisionOutCome = useSelector(
    (state) => state.configSlice.decisionOutCome
  );

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    console.log("communicationStageAccessMap ", communicationStageAccessMap);

    if (communicationStageAccessMap.size > 0) {
      if (communicationStageAccessMap.get("READ") === true) {
        setUserHasViewAccess(true);
      }
      if (communicationStageAccessMap.get("LIST") === true) {
        setUserHasListAccess(true);
      }
      if (communicationStageAccessMap.get("EDIT") === true) {
        setUserHasEditAccess(true);
      }
      if (communicationStageAccessMap.get("WRITE") === true) {
        setUserHasWriteAccess(true);
      }
      if (communicationStageAccessMap.get("EDIT") === true) {
        setUserHasImportAccess(true);
      }
    }

    if (communicationMessageAccessMap.size > 0) {
      if (communicationMessageAccessMap.get("WRITE") === true) {
        setUserHasCommunicationAccess(true);
      }
    }
  }, [communicationStageAccessMap, communicationMessageAccessMap]);

  useEffect(() => {
    dispatch(getClaimDetailsAPI({ claimId }));
  }, [dispatch, claimId]);

  useEffect(() => {
    if (claimDetails) {
      setCurrentStage(claimDetails.claim?.stage.name ?? null);
      setEditCommunicationDetailsInProgress(false);

      console.log("claimDetails ", claimDetails.communication);

      if (claimDetails.communication !== undefined) {
        setCommunicationDataExists(true);
      } else {
        setCommunicationDataExists(false);
      }

      if (claimDetails.claim?.stage.name === "Communication") {
        setIsCurrentStageInCommunication(true);
        // setCommunicationDataExists(true);
      } else {
        setIsCurrentStageInCommunication(false);
      }

      const decisionDetails = claimDetails.decision || {};
      setDecision(decisionDetails?.decision_status?.name ?? "");
      setApprovedPayout(decisionDetails?.approved?.payout ?? "");

      const communicationDetail = claimDetails.communication || {};
      setCommunicationDate(
        communicationDetail?.communication_date
          ? format(communicationDetail?.communication_date, dateFormat) ?? null
          : null
      );
      setCommunicationOutCome(communicationDetail?.communication_outcome ?? "");
      setAgentRemarks(communicationDetail?.remarks ?? "");

      setCustomerId(claimDetails.customer?.info?.id || "");

      if (
        communicationDetail?.metadata &&
        communicationDetail?.metadata.length > 0
      ) {
        const metaDataDetails = communicationDetail?.metadata.map((item) => {
          return {
            mode: item.id,
            contact: item.value,
          };
        });
        console.log("payoutDetails metaDataDetails ", metaDataDetails);
        setDefaultMetaDataList(metaDataDetails ?? []);
      }
    }
  }, [claimDetails]);

  const handleSMSChange = (e) => {
    const selectedTemplateName = e.target.value;
    setTemplateName(selectedTemplateName);
    const selectedTemplate = smsTemplateList.find(
      (item) => item.template_name === selectedTemplateName
    );
    setMessage(selectedTemplate.template_params.template_body);
  };

  const handleEmailChange = (e) => {
    const selectedTemplateName = e.target.value;
    setTemplateName(selectedTemplateName);
    const selectedTemplate = emailTemplateList.find(
      (item) => item.template_name === selectedTemplateName
    );
    setMessage(selectedTemplate.template_params.template_body);
  };

  const handleWhatsAppChange = (e) => {
    const selectedTemplateName = e.target.value;
    setTemplateName(selectedTemplateName);
    const selectedTemplate = whatsAppTemplateList.find(
      (item) => item.template_name === selectedTemplateName
    );
    setMessage(selectedTemplate.components[0].template_body);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (commChannel.id === "call") {
      newErrors.contact = contact === "";
      setErrors(newErrors);
    } else {
      newErrors.templateName = templateName === "";
      newErrors.message = message === "";
      newErrors.contact = contact === "";
      setErrors(newErrors);
    }

    const hasErrors = Object.values(newErrors).some((error) => error);

    if (hasErrors) {
      displayAlert(
        "error",
        "Unable to send out communication. Please fill all the necessary details."
      );
    }

    if (!hasErrors) {
      // Handle form submission
      console.log("Form submitted");
      setBackdropOpen(true);

      const requestData = {
        name: templateName,
        type: commChannel.id,
        body: message,
        contact: contact,
        customer_id: customerId,
      };
      const data = { requestData, claimId };
      dispatch(sendCommunicationTemplate(data)).then((responseData) => {
        console.log("sendCommunicationTemplate component data", responseData);
        setBackdropOpen(false);
        if (responseData.payload.status === 200) {
          displayAlert("success", MESSAGES.MESSAGE_SUCCESS);
        } else {
          displayAlert("error", MESSAGES.ERROR_PLS_TRY_AGAIN);
        }
      });
    }
  };

  // Fetch Template data
  useEffect(() => {
    if (message !== "") {
      setBackdropOpen(true);
      setDisplayMessage("");
      console.log("Renderer API call");
      const requestData = {
        name: templateName,
        type: commChannel.id,
        body: message,
      };
      const data = { requestData, claimId };
      dispatch(renderCommunicationTemplate(data)).then((responseData) => {
        console.log("renderCommunicationTemplate component data", responseData);
        setBackdropOpen(false);
        if (responseData.payload.status === 200) {
          setDisplayMessage(responseData.payload.data.template_body);
          setMessage(responseData.payload.data.template_body);
        } else {
          displayAlert("error", MESSAGES.ERROR_PLS_TRY_AGAIN);
        }
      });
    }
  }, [message]);

  // SHOW LOADERS FOR API CALL
  useEffect(() => {
    if (claimDetailsLoading || templateLoading) {
      setBackdropOpen(true);
    } else {
      setBackdropOpen(false);
    }
  }, [claimDetailsLoading, templateLoading]);

  // META DATA DATA
  const handleDateChange = (date) => {
    console.log("handleChange  date ", format(date, dateFormat));
    if (date) {
      setCommunicationDate(format(date, dateFormat));
    } else {
      setCommunicationDate(null);
    }
  };

  const handleCommunicationDetailsSubmit = (data) => {
    // Handle form submission
    console.log("Submitted Data: ", data);
  };

  // Handle data changes in the form in real-time
  const handleDataChange = (data) => {
    console.log("Real-time Form Data: ", data);
    setMetaDataList(data);
  };

  // SAVE & NEXT CLICK HANDLERS
  const handleClick = (claimStage, buttonStatus) => async (e) => {
    e.preventDefault();
    if (buttonStatus !== undefined && buttonStatus === "previous") {
      onNextButtonClick(claimStage, "previous");
    } else {
      if (communicationDate === null) {
        setDecisionDateError(true);
        setDecisionOutcomeError(true);
        return;
      } else if (communicationOutcome === "") {
        setDecisionDateError(false);
        setDecisionOutcomeError(true);
        return;
      } else {
        var metaDataDetails = null;
        if (metaDataList && metaDataList.length > 0) {
          metaDataDetails = metaDataList.map((item) => {
            const metaDataName = filteredMetaData.find(
              (opt) => opt.id === item.mode
            );
            return {
              id: item.mode,
              name: metaDataName?.name || "",
              value: item.contact,
            };
          });
        }
        let requestData = {
          communication_date: communicationDate,
          communication_outcome: communicationOutcome,
          metadata: metaDataDetails,
          remarks: agentRemarks,
        };
        submitCommunicationData(requestData, claimStage);
      }
    }
  };

  // fetch templates API
  const fetchTemplateData = async () => {
    try {
      console.log("fetchTemplateData: claimId ", claimId);
      console.log("fetchTemplateData: commChannel ", commChannel);

      let commChannelId = commChannel.id;

      const data = { commChannelId, claimId };
      const response = await dispatch(fetchCommunicationTemplate(data));
      const responsePayload = response.payload;
      console.log("fetchTemplateData response ", responsePayload);
      console.log("fetchTemplateData response ", commChannel);
      if (responsePayload.status === 200) {
        if (commChannel.id === "sms") {
          setSmsTemplateList(responsePayload.data.templates);
        } else if (commChannel.id === "email") {
          setEmailTemplateList(responsePayload.data.templates);
        } else if (commChannel.id === "whatsapp") {
          setwhatsAppTemplateList(responsePayload.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch Template data
  useEffect(() => {
    if (commChannel.name !== "") {
      console.log("API call");
      fetchTemplateData();
    }
  }, [commChannel]);

  const submitCommunicationData = async (requestData, claimStage) => {
    try {
      console.log("submitCommunicationData: ", requestData);
      const data = { requestData, claimId };
      const response = await dispatch(submitCommunicationDetails(data));
      const responsePayload = response.payload;
      console.log("submitCommunicationData response ", responsePayload);
      if (responsePayload.status === 200) {
        setEditCommunicationDetailsInProgress(false);
        setCommunicationDataExists(true);
        if (claimStage !== -1) {
          onNextButtonClick(claimStage, "next", {
            id: MESSAGES.CLAIM_STAGE_PAYOUT_ID,
            name: MESSAGES.CLAIM_STAGE_PAYOUT,
          });
        } else {
          displayAlert("success", MESSAGES.SAVED_SUCCESFULLY);
        }
      } else {
        displayAlert("error", MESSAGES.SAVE_FAILED);
      }
    } catch (error) {
      displayAlert("error", MESSAGES.SAVE_FAILED);
    }
  };

  if (
    params.claimId !== null &&
    params.claimId !== undefined &&
    !backdropOpen &&
    !claimDetailsLoading &&
    !userHasViewAccess
  ) {
    return <PageAccessDenied />;
  }

  if (
    params.claimId === null ||
    params.claimId === undefined ||
    (!claimDetailsLoading &&
      currentStage !== null &&
      (currentStage === MESSAGES.CLAIM_STAGE_FILING ||
        currentStage === MESSAGES.CLAIM_STAGE_UPLOAD_DOC ||
        currentStage === MESSAGES.CLAIM_STAGE_VERIFY_DOC ||
        currentStage === MESSAGES.CLAIM_STAGE_DECISION))
  ) {
    return <ClaimNotReached />;
  }

  if (claimDetailsLoading) {
    return <CommonBackDrop backdropOpen={backdropOpen} />;
  }

  if (!claimDetailsLoading) {
    return (
      <Box>
        {/* LOADER */}
        <CommonBackDrop backdropOpen={backdropOpen} />

        <Card id="claimantinfo--main__card" sx={{ m: 4, p: 4 }}>
          {/* <Typography variant="h6">Decision Communication</Typography> */}

          <Box display="flex">
            <Typography variant="h6" component="div">
              Decision Communication
            </Typography>
            <Spacer value={1} />
            {userHasEditAccess &&
              isCurrentStageInCommunication &&
              communicationDataExists &&
              !editCommunicationDetailsInProgress && (
                <Tooltip title="Edit Communication Details">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(e) => {
                      setEditCommunicationDetailsInProgress(true);
                      setCommunicationDataExists(false);
                    }}
                  >
                    <Edit fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
          </Box>

          <Box sx={{ m: 2, p: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  id="claimantinfo--primaryno__textfield"
                  label="Decision"
                  name="approved-payout"
                  variant="outlined"
                  fullWidth
                  value={decision}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="claimantinfo--primaryno__textfield"
                  label="Approved Payout"
                  name="approved-payout"
                  variant="outlined"
                  fullWidth
                  value={approvedPayout}
                  disabled
                />
              </Grid>
              <>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={
                        isCurrentStageInCommunication
                          ? communicationDataExists
                            ? true
                            : false
                          : true
                      }
                      disableFuture
                      format="dd/MM/yyyy"
                      views={["year", "month", "day"]}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: decisionDateError,
                          helperText: decisionDateError
                            ? "Select Decision Date"
                            : "",
                        },
                      }}
                      label="Decision Communication Date *"
                      value={
                        communicationDate
                          ? parse(communicationDate, dateFormat, new Date())
                          : null
                      }
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    error={decisionOutcomeError}
                    disabled={
                      isCurrentStageInCommunication
                        ? communicationDataExists
                          ? true
                          : false
                        : true
                    }
                    fullWidth
                  >
                    <InputLabel id="claimantinfo--relationship__inputlabel">
                      Decision Communication Outcome *
                    </InputLabel>
                    <Select
                      labelId="claimantinfo--relationship__selectlabel"
                      id="claimantinfo--relationship__select"
                      name="claimantRelationship"
                      label="Decision Communication Outcome *"
                      value={communicationOutcome?.id || ""}
                      onChange={(e, key) => {
                        const data = {
                          id: key.props.id,
                          name: key.props.children,
                        };
                        setCommunicationOutCome(data);
                      }}
                    >
                      {decisionOutCome.map((item) => (
                        <MenuItem key={item.id} value={item.id} id={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {decisionOutcomeError ? "Select Decision Outcome" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            </Grid>

            {editCommunicationDetailsInProgress && (
              <Grid container spacing={10} sx={{ pt: 4 }}>
                <Grid item xs={6} textAlign="right">
                  <Button
                    variant="outlined"
                    size="big"
                    sx={{ p: 1, m: 1, minWidth: 200 }}
                    onClick={handleClick(-1)}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    size="big"
                    sx={{ p: 1, m: 1, minWidth: 200 }}
                    onClick={(e) => {
                      setEditCommunicationDetailsInProgress(false);
                      setCommunicationDataExists(true);

                      setDecisionDateError(false);
                      setDecisionOutcomeError(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Card>

        <Card id="claimantinfo--main__card" sx={{ m: 4, p: 4 }}>
          <Typography variant="h6">Communication</Typography>
          <Box sx={{ m: 2, p: 2 }}>
            <Grid
              style={{
                pointerEvents:
                  isCurrentStageInCommunication && userHasCommunicationAccess
                    ? "auto"
                    : "none",
                opacity:
                  isCurrentStageInCommunication && userHasCommunicationAccess
                    ? 1
                    : 0.5,
              }}
              container
              spacing={4}
            >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="claimantinfo--relationship__inputlabel">
                    Mode of Communication
                  </InputLabel>
                  <Select
                    labelId="claimantinfo--relationship__selectlabel"
                    id="claimantinfo--relationship__select"
                    name="claimantRelationship"
                    label="Mode of Communication"
                    value={commChannel?.id || ""}
                    onChange={(e, key) => {
                      const data = {
                        id: key.props.id,
                        name: key.props.children,
                      };
                      setCommChannel(data);
                    }}
                  >
                    {communicationMode.map((item) => (
                      <MenuItem key={item.id} value={item.id} id={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {commChannel.id === "call" && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      id="claimantinfo--primaryno__textfield"
                      label="Phone Number"
                      name="communication_phone_no"
                      variant="outlined"
                      fullWidth
                      value={contact}
                      onChange={(e) => {
                        setContact(e.target.value);
                      }}
                      helperText={
                        errors.contact ? "Phone number is required" : ""
                      }
                      error={errors.contact}
                    />
                  </Grid>
                </>
              )}
              {commChannel.id === "sms" && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      id="claimantinfo--primaryno__textfield"
                      label="Phone Number"
                      name="approved-payout"
                      variant="outlined"
                      fullWidth
                      value={contact}
                      onChange={(e) => {
                        setContact(e.target.value);
                      }}
                      helperText={
                        errors.contact ? "Phone number is required" : ""
                      }
                      error={errors.contact}
                    />
                  </Grid>
                  {smsTemplateList !== null && (
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="claimantinfo--relationship__inputlabel">
                          Select Template
                        </InputLabel>
                        <Select
                          onChange={handleSMSChange}
                          labelId="claimantinfo--relationship__selectlabel"
                          id="claimantinfo--relationship__select"
                          name="claimantRelationship"
                          label="Select Template"
                        >
                          {smsTemplateList !== null &&
                            smsTemplateList.map((item) => (
                              <MenuItem
                                key={item.id.timestamp}
                                value={item.template_name}
                              >
                                {item.template_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}
              {commChannel.id === "whatsapp" && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      id="claimantinfo--primaryno__textfield"
                      label="WhatsApp Number"
                      name="approved-payout"
                      variant="outlined"
                      fullWidth
                      value={contact}
                      onChange={(e) => {
                        setContact(e.target.value);
                      }}
                      helperText={
                        errors.contact ? "WhatsApp number is required" : ""
                      }
                      error={errors.contact}
                    />
                  </Grid>
                  {whatsAppTemplateList !== null && (
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="claimantinfo--relationship__inputlabel">
                          Select Template
                        </InputLabel>
                        <Select
                          // onChange={(e) => {
                          //   setWhatsappTemplate(e.target.value);
                          // }}
                          onChange={handleWhatsAppChange}
                          labelId="claimantinfo--relationship__selectlabel"
                          id="claimantinfo--relationship__select"
                          name="claimantRelationship"
                          label="Select Template"
                        >
                          {whatsAppTemplateList !== null &&
                            whatsAppTemplateList.map((item) => (
                              <MenuItem
                                key={item.id.timestamp}
                                value={item.template_name}
                                // id={item.id}
                              >
                                {item.template_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}
              {commChannel.id === "email" && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      id="claimantinfo--primaryno__textfield"
                      label="Email Address"
                      name="approved-payout"
                      variant="outlined"
                      fullWidth
                      value={contact}
                      onChange={(e) => {
                        setContact(e.target.value);
                      }}
                      error={errors.contact}
                      helperText={
                        errors.contact ? "Enter valid email address" : ""
                      }
                    />
                  </Grid>
                  {emailTemplateList !== null && (
                    <Grid item xs={6}>
                      <FormControl fullWidth error={errors.templateName}>
                        <InputLabel id="claimantinfo--relationship__inputlabel">
                          Select Template
                        </InputLabel>
                        <Select
                          onChange={handleEmailChange}
                          labelId="claimantinfo--relationship__selectlabel"
                          id="claimantinfo--relationship__select"
                          name="claimantRelationship"
                          label="Select Template"
                        >
                          {emailTemplateList !== null &&
                            emailTemplateList.map((item) => (
                              <MenuItem
                                key={item.id.timestamp}
                                value={item.template_name}
                                // id={item.id}
                              >
                                {item.template_name}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          {errors.templateName ? "Select Template" : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}
              {commChannel.id !== "" &&
                commChannel.id !== "call" &&
                commChannel.id !== undefined &&
                commChannel.id !== "no_communication" && (
                  <Grid item xs={12}>
                    <TextField
                      id="agentnotes__textfield"
                      label="Details of the message"
                      name="agentRemark"
                      multiline
                      rows={4}
                      onChange={(event) =>
                        setDisplayMessage(event.target.value)
                      }
                      value={displayMessage}
                      fullWidth
                      helperText={errors.message ? "Message is required" : ""}
                      error={errors.message}
                    />
                  </Grid>
                )}
            </Grid>

            {commChannel.id !== "" &&
              commChannel.id !== undefined &&
              commChannel.id !== "no_communication" &&
              commChannel.name !== "" && (
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="outlined"
                    size="big"
                    sx={{ p: 2, m: 2, minWidth: 200 }}
                    onClick={handleSendMessage}
                    endIcon={<Send />}
                  >
                    Send Message
                  </Button>
                </Grid>
              )}
          </Box>
        </Card>

        {/* COMMUNICATION DETAILS */}
        {filteredMetaData && filteredMetaData.length > 0 && (
          <Box sx={{ m: 4 }}>
            <CommunicationDetailsCard
              enable={userHasEditAccess && isCurrentStageInCommunication}
              title="Communication Additional Information"
              defaultMetaDataList={defaultMetaDataList}
              optionsList={filteredMetaData}
              onSubmit={handleCommunicationDetailsSubmit}
              onDataChange={handleDataChange}
            />
          </Box>
        )}

        {/* AGENT REMARKS */}
        <Box sx={{ m: 4 }}>
          <AgentRemarks
            agentRemarks={agentRemarks}
            setAgentRemarks={setAgentRemarks}
            buttonStatus={isCurrentStageInCommunication ? false : true}
          />
        </Box>

        {/* NEXT AND PREVIOUS BUTTONS */}
        <Grid container spacing={2}>
          <Grid item xs={4} textAlign="right">
            <Button
              variant="contained"
              size="big"
              sx={{ p: 2, m: 4, minWidth: 200 }}
              onClick={handleClick(3, "previous")}
              startIcon={<NavigateBefore />}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={4} textAlign="center">
            <Button
              variant="contained"
              size="big"
              sx={{ p: 2, m: 4, minWidth: 200 }}
              disabled={
                currentStage === MESSAGES.CLAIM_STAGE_CLOSED ||
                currentStage === MESSAGES.CLAIM_STAGE_CLOSURE
                  ? true
                  : isCurrentStageInCommunication && userHasWriteAccess
                  ? false
                  : true
              }
              onClick={handleClick(-1)}
              startIcon={<Save />}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={4} textAlign="left">
            <Button
              variant="contained"
              size="big"
              sx={{ p: 2, m: 4, minWidth: 200 }}
              disabled={
                currentStage === MESSAGES.CLAIM_STAGE_CLOSED ||
                currentStage === MESSAGES.CLAIM_STAGE_CLOSURE
                  ? true
                  : isCurrentStageInCommunication && userHasWriteAccess
                  ? false
                  : true
              }
              onClick={handleClick(5, "next")}
              endIcon={<NavigateNextIcon />}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
};

export default ClaimsCommunication;
