import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  NavigateBefore,
  Save,
  Edit,
  CloudUpload,
  FileUpload,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import { submitPayoutDetails } from "services/api/payout-api-service";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parse } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getClaimDetailsAPI } from "services/api/getclaimdetails-api-service";
import { sendPaymentDetails } from "services/api/payment-api-service";
import { useParams } from "react-router-dom";
import { MESSAGES } from "../../../components/utils/message-constants";
import Spacer from "ui/components/utils/spacer";
import { useDropzone } from "react-dropzone";
import { formatFileSize, getFileType } from "ui/components/utils/utils";
import UploadDocumentTable from "./upload-documents/upload-document-table.component";
import UploadDocumentConfirmationDialog from "./upload-documents/upload-document-confirmation-dialog.component";
import { uploadDocumentDetails } from "services/api/upload-document-api-service";
import AgentRemarks from "./agent-remarks";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import { CLAIM_PAYOUT_PAGE_ACCESS } from "ui/components/utils/role-constants";
import PageAccessDenied from "ui/components/page-access-denied/page-access-denied.component";
import ClaimNotReached from "ui/components/utils/claim-not-reached.component";
import { FormatDateUtil } from "utils/formatdate";
import FormHelpText from "rsuite/esm/FormHelpText";
import CommunicationDetailsCard from "ui/components/utils/communication-detail-card.component";
import { de } from "date-fns/locale";

const ClaimsPayout = ({
  onNextButtonClick,
  displayAlert,
  roleAndPrivilageMap,
  showLoadingBackdrop,
}) => {
  const dateFormat = "yyyy-MM-dd";
  const dispatch = useDispatch();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [currentStage, setCurrentStage] = useState(null);
  const [agentRemarks, setAgentRemarks] = useState("");
  const [refreshClaimDetailsAPI, setRefreshClaimDetailsAPI] = useState(null);
  const [itemsToShow, setItemsToShow] = useState(5);
  const [metaDataList, setMetaDataList] = useState([]);
  const [defaultMetaDataList, setDefaultMetaDataList] = useState([]);

  const [enableAddDocumentsView, setEnableAddDocumentsView] = useState(false);
  const [payoutMode, setPayoutMode] = useState({ id: "", name: "", type: "" });
  const [payoutFields, setPayoutFields] = useState(null);

  const [isCurrentStageInPayout, setIsCurrentStageInPayout] = useState(false);
  const [submitEditedDetails, setSubmitEditedDetails] = useState(false);

  const [payoutDataExists, setPayoutDataExists] = useState(false);
  const [editPayoutDetailsInProgress, setEditPayoutDetailsInProgress] =
    useState(false);

  const [paymentDataExists, setPaymentDataExists] = useState(false);
  const [editPaymentDetailsInProgress, setEditPaymentDetailsInProgress] =
    useState(false);
  const [disableSelectFiles, setDisableSelectFiles] = useState(true);

  const params = useParams();
  const claimId = params.claimId;

  const [files, setFiles] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [documentRemarks, setDocumentRemarks] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const fileObject = {
    id: "",
    type: "",
    link: "",
    name: "",
    filename: "",
    mime_type: "",
    channel: "",
    filesize: "",
    remarks: "",
    uploaded_by: "",
    uploaded_at: null,
    upload: true,
    tempId: null,
  };

  console.log("Claim id  ", params.claimId);

  const payoutStageAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIM_PAYOUT_PAGE_ACCESS
  );

  const [userHasViewAccess, setUserHasViewAccess] = useState(false);
  const [userHasListAccess, setUserHasListAccess] = useState(false);
  const [userHasEditAccess, setUserHasEditAccess] = useState(false);
  const [userHasWriteAccess, setUserHasWriteAccess] = useState(false);
  const [userHasImportAccess, setUserHasImportAccess] = useState(false);

  // CLAIM STATE
  const claimDetails = useSelector(
    (state) => state.claimDetailsSlice.claimDetails
  );
  const claimDetailsLoading = useSelector(
    (state) => state.claimDetailsSlice.loading
  );
  const claimDetailsError = useSelector(
    (state) => state.claimDetailsSlice.error
  );

  // META DATA
  const allMetaData = useSelector((state) => state.configSlice.metadata);
  // Filter for payout stage
  const filteredMetaData = allMetaData?.filter((stages) =>
    stages.filter.some((stage) => stage === MESSAGES.CLAIM_STAGE_PAYOUT_ID)
  );
  console.log("metaData", allMetaData, filteredMetaData);

  const [commFormValues, setCommFormValues] = useState([
    { mode: { id: "", name: "" }, contact: "" },
  ]);
  const [communicationModeList, setCommunicationModeList] = useState([
    { id: 1, name: "Email" },
    { id: 2, name: "Phone" },
    { id: 3, name: "SMS" },
  ]);
  const [
    editCommunicationDetailsInProgress,
    setEditCommunicationDetailsInProgress,
  ] = useState(true);
  const [communicationDataExists, setCommunicationDataExists] = useState(false);

  const handleCommunicationDetailsSubmit = (data) => {
    // Handle form submission
    console.log("Submitted Data: ", data);
  };

  // Handle data changes in the form in real-time
  const handleDataChange = (data) => {
    console.log("Real-time Form Data: ", data);
    setMetaDataList(data);
  };

  const submitPayoutDetailsLoading = useSelector(
    (state) => state.payoutSlice.loading
  );

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    console.log("payoutStageAccessMap ", payoutStageAccessMap);

    if (payoutStageAccessMap.size > 0) {
      if (payoutStageAccessMap.get("READ") === true) {
        setUserHasViewAccess(true);
      }
      if (payoutStageAccessMap.get("LIST") === true) {
        setUserHasListAccess(true);
      }
      if (payoutStageAccessMap.get("EDIT") === true) {
        setUserHasEditAccess(true);
      }
      if (payoutStageAccessMap.get("WRITE") === true) {
        setUserHasWriteAccess(true);
      }
      if (payoutStageAccessMap.get("EDIT") === true) {
        setUserHasImportAccess(true);
      }
    }
  }, [payoutStageAccessMap]);

  // CONFIG STATE
  const paymentModes = useSelector((state) => state.configSlice.paymentModes);
  const paymentBanks = useSelector((state) => state.configSlice.paymentBanks);

  //ERROR
  const [paymentModeError, setPaymentModeError] = useState(false);
  const [values, setValues] = useState({
    bank_name: "",
    branch_name: "",
    bank_acc_no: "",
    bank_dd_no: "",
    cheque_no: "",
    acc_holder_name: "",
    bank_code: "",
    branch_city: "",
    payout_date: "",
    amount: "",
  });

  const [errors, setErrors] = useState({
    bank_name: "",
    branch_name: "",
    bank_acc_no: "",
    bank_dd_no: "",
    cheque_no: "",
    acc_holder_name: "",
    bank_code: "",
    branch_city: "",
    payout_date: "",
    amount: "",
  });

  const handleShowMore = () => {
    setItemsToShow((prev) => prev + 5);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleBankNameChange = (e, key) => {
    // const { name, value } = e.target;
    const data = {
      id: key.props.id,
      name: key.props.children,
    };
    setValues({
      ...values,
      [e.target.name]: data,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const handleDateChange = (date) => {
    console.log("handleChange  date ", format(date, dateFormat));
    if (date) {
      setValues({
        ...values,
        ["payout_date"]: format(date, dateFormat),
      });
      setErrors({
        ...errors,
        ["payout_date"]: "",
      });
    } else {
      setErrors({
        ...errors,
        ["payout_date"]: "Payout Date is required",
      });
    }
  };

  const validateFields = () => {
    let tempErrors = {};
    if (!values.bank_name) tempErrors.bank_name = "Bank Name is required";
    if (!values.branch_name) tempErrors.branch_name = "Branch Name is required";

    if (payoutMode.id === "bank_transfer" && !values.bank_acc_no)
      tempErrors.bank_acc_no = "Bank Account Number is required";

    if (payoutMode.id === "demand_draft" && !values.bank_dd_no)
      tempErrors.bank_dd_no = "Demant Draft Number is required";

    if (payoutMode.id === "bank_cheque" && !values.cheque_no)
      tempErrors.cheque_no = "Bank Account Number is required";

    if (!values.acc_holder_name)
      tempErrors.acc_holder_name = "Account Holder Name required";
    if (!values.bank_code) tempErrors.bank_code = "Bank IFSC Code is required";
    if (!values.branch_city) tempErrors.branch_city = "Bank Branch is required";
    if (!values.payout_date) tempErrors.payout_date = "Payout Date is required";
    if (!values.amount) tempErrors.amount = "Amount is required";
    setErrors(tempErrors);

    // Return true if there are no errors
    return Object.keys(tempErrors).length === 0;
  };

  const validateMobileMoneyFields = () => {
    let tempErrors = {};

    if (!values.acc_holder_name)
      tempErrors.acc_holder_name = "Holder Name required";
    if (!values.bank_code) tempErrors.bank_code = "Code is required";

    if (!values.payout_date) tempErrors.payout_date = "Payout Date is required";
    if (!values.amount) tempErrors.amount = "Amount is required";

    setErrors(tempErrors);

    // Return true if there are no errors
    return Object.keys(tempErrors).length === 0;
  };

  useEffect(() => {
    dispatch(getClaimDetailsAPI({ claimId }));
  }, [dispatch, claimId, refreshClaimDetailsAPI]);

  useEffect(() => {
    if (documentName === "" || documentRemarks === "") {
      setDisableSelectFiles(true);
    } else {
      setDisableSelectFiles(false);
    }
  }, [documentName, documentRemarks]);

  useEffect(() => {
    if (claimDetails) {
      console.log(
        "claimDetails refreshClaimDetailsAPI ",
        refreshClaimDetailsAPI
      );
      if (refreshClaimDetailsAPI === null) {
        setCurrentStage(claimDetails.claim?.stage.name ?? null);

        if (claimDetails.payout !== undefined) {
          setPayoutDataExists(true);
        } else {
          setPayoutDataExists(false);
        }

        if (claimDetails.payment !== undefined) {
          setPaymentDataExists(true);
        } else {
          setPaymentDataExists(false);
        }

        if (claimDetails.claim?.stage.name === "Payout") {
          setIsCurrentStageInPayout(true);
        } else {
          setIsCurrentStageInPayout(false);
        }

        const decisionApprovedPayoutAmount =
          claimDetails.decision?.approved?.payout ?? 0;
        const payoutDetails = claimDetails.payout || {};
        const paymentDetails =
          claimDetails.payment?.bank ||
          claimDetails.payment?.demand_draft ||
          claimDetails.payment?.cheque ||
          claimDetails.payment?.mobile_money ||
          {};

        console.log("payoutDetails ", payoutDetails);
        setPayoutMode(claimDetails?.payment?.mode ?? "");
        setAgentRemarks(payoutDetails?.remarks ?? "");

        if (payoutDetails?.metadata && payoutDetails?.metadata.length > 0) {
          const metaDataDetails = payoutDetails?.metadata.map((item) => {
            return {
              mode: item.id,
              contact: item.value,
            };
          });
          console.log("payoutDetails metaDataDetails ", metaDataDetails);
          setDefaultMetaDataList(metaDataDetails ?? []);
        }

        setValues({
          ...values,
          ["bank_name"]: paymentDetails?.bank_name ?? "",
          ["branch_name"]: paymentDetails?.branch_name ?? "" ?? "",
          ["bank_acc_no"]: paymentDetails?.bank_acc_no ?? "",
          ["bank_dd_no"]: paymentDetails?.bank_dd_no ?? "",
          ["cheque_no"]: paymentDetails?.cheque_no ?? "",
          ["acc_holder_name"]:
            paymentDetails?.acc_holder_name ?? paymentDetails?.name ?? "",
          ["bank_code"]:
            paymentDetails?.bank_code ?? paymentDetails?.code ?? "",
          ["branch_city"]:
            paymentDetails?.branch_city ?? paymentDetails?.city ?? "",
          ["payout_date"]: payoutDetails?.payout_date
            ? format(payoutDetails.payout_date, dateFormat) ?? null
            : null,
          ["amount"]: payoutDetails?.amount ?? decisionApprovedPayoutAmount,
        });
      }
      const payoutDetails = claimDetails.payout || {};
      if (payoutDetails) {
        setDocumentsList(payoutDetails.documents ?? []);
      }
    }
  }, [claimDetails, refreshClaimDetailsAPI]);

  // SHOW LOADERS FOR API CALL
  useEffect(() => {
    if (claimDetailsLoading || submitPayoutDetailsLoading) {
      setBackdropOpen(true);
    } else {
      setBackdropOpen(false);
    }
  }, [claimDetailsLoading, submitPayoutDetailsLoading]);

  // claimStage -1 -> SAVE, -2 -> SAVE EDITED DETAILS
  const handleClick = (apiCall, onButtonClick) => async (e) => {
    e.preventDefault();
    var metaDataDetails = null;
    if (metaDataList && metaDataList.length > 0) {
      metaDataDetails = metaDataList.map((item) => {
        const metaDataName = filteredMetaData.find(
          (opt) => opt.id === item.mode
        );
        return {
          id: item.mode,
          name: metaDataName?.name || "",
          value: item.contact,
        };
      });
    }

    console.log(
      "payoutMode.id, type ",
      payoutMode.id,
      payoutMode.type,
      metaDataList,
      metaDataDetails
    );

    if (payoutMode.id === "" || payoutMode.id === undefined) {
      setPaymentModeError(true);
    } else if (payoutMode.id === "none") {
      submitPaymentAPI("payout", onButtonClick, metaDataDetails);
    } else if (
      (payoutMode.type !== null && payoutMode.type === "mobile_money") ||
      payoutMode.id === "mobile_money"
    ) {
      setPaymentModeError(false);

      console.log(
        "payoutMode validateMobileMoneyFields ",
        validateMobileMoneyFields
      );
      if (validateMobileMoneyFields()) {
        if (apiCall === "payment") {
          submitPaymentAPI("", onButtonClick, metaDataDetails);
        } else if (apiCall === "payout") {
          submitPayoutAPI(onButtonClick, metaDataDetails);
        } else {
          submitPaymentAPI("payout", onButtonClick, metaDataDetails);
        }
      }
    } else if (validateFields()) {
      setPaymentModeError(false);

      if (apiCall === "payment") {
        submitPaymentAPI("", onButtonClick, metaDataDetails);
      } else if (apiCall === "payout") {
        submitPayoutAPI(onButtonClick, metaDataDetails);
      } else {
        submitPaymentAPI("payout", onButtonClick, metaDataDetails);
      }
    } else {
      displayAlert("error", "Payout mode error, pls refresh and try again");
    }
  };

  const submitPaymentAPI = async (apiCall, onButtonClick, metaDataDetails) => {
    try {
      const submitData = { ...values };
      var paymentRequestBody = {};

      delete submitData.payout_date;
      delete submitData.amount;

      // Remove unnecessary fields based on the payment method
      if (payoutMode.id === "bank_transfer") {
        delete submitData.bank_dd_no;
        delete submitData.cheque_no;
        paymentRequestBody = { bank: submitData };
      } else if (payoutMode.id === "demand_draft") {
        delete submitData.bank_acc_no;
        delete submitData.cheque_no;
        paymentRequestBody = {
          demand_draft: submitData,
        };
      } else if (payoutMode.id === "bank_cheque") {
        delete submitData.bank_acc_no;
        delete submitData.bank_dd_no;
        paymentRequestBody = { cheque: submitData };
      } else if (payoutMode.type === "mobile_money") {
        delete submitData.bank_acc_no;
        delete submitData.bank_dd_no;
        delete submitData.cheque_no;

        const requestData = {
          name: submitData.acc_holder_name,
          code: submitData.bank_code,
          city: submitData.branch_city,
          msisdn: "",
          relationship: "",
          remarks: "",
        };
        paymentRequestBody = { mobile_money: requestData };
      }

      paymentRequestBody = {
        ...paymentRequestBody,
        mode: payoutMode,
        // remarks: agentRemarks,
      };

      console.log("submitPayoutAPI: ", paymentRequestBody);
      const data = { paymentRequestBody, claimId };
      const response = await dispatch(sendPaymentDetails(data));
      const responsePayload = response.payload;

      if (responsePayload.status === 200) {
        console.log("submitPayoutAPI response 200 ", responsePayload);

        if (apiCall === "payout") {
          submitPayoutAPI(onButtonClick, metaDataDetails);
        } else {
          setEditPaymentDetailsInProgress(false);
          setPaymentDataExists(true);
          displayAlert("success", MESSAGES.SAVED_SUCCESFULLY);
        }
      } else {
        displayAlert("error", MESSAGES.SAVE_FAILED);
      }
    } catch (error) {
      displayAlert("error", MESSAGES.SAVE_FAILED);
    }
  };

  const submitPayoutAPI = async (onButtonClick, metaDataDetails) => {
    try {
      const submitData = { ...values };
      let payoutRequestBody = {
        remarks: agentRemarks,
        amount: submitData.amount,
        payout_date: submitData.payout_date,
        metadata: metaDataDetails,
      };

      const data = { payoutRequestBody, claimId };
      const response = await dispatch(submitPayoutDetails(data));
      const responsePayload = response.payload;
      console.log("submitPayoutAPI response ", responsePayload);
      console.log("submitPayoutAPI response onButtonClick ", onButtonClick);

      if (responsePayload.status === 200) {
        if (onButtonClick === "next") {
          onNextButtonClick(6, "next", {
            id: MESSAGES.CLAIM_STAGE_CLOSURE_ID,
            name: MESSAGES.CLAIM_STAGE_CLOSURE,
          });
          // displayAlert("success", MESSAGES.SAVED_SUCCESFULLY);
        } else {
          setDocumentName("");
          setDocumentRemarks("");
          setEnableAddDocumentsView(false);

          setEditPaymentDetailsInProgress(false);
          setPaymentDataExists(true);
          setEditPayoutDetailsInProgress(false);
          setPayoutDataExists(true);

          displayAlert("success", MESSAGES.SAVED_SUCCESFULLY);
        }
      } else {
        displayAlert("error", MESSAGES.SAVE_FAILED);
      }
    } catch (error) {
      displayAlert("error", MESSAGES.SAVE_FAILED);
      console.error("Error fetching data:", error);
    }
  };

  // ON UPLOAD BUTTON CLICK
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (documentRemarks === "") {
      displayAlert("info", "Please enter document remarks");
      return;
    }
    if (files.length === 0) return;
    setOpenDialog(true);
  };

  const handleDialogAccept = async () => {
    handleDialogClose();
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      var formData = new FormData();

      formData.append("document_name", file.name);
      formData.append("remarks", file.remarks);

      formData.append("filesize", file.filesize);
      formData.append("filename", file.filename);
      formData.append("file", file.file);

      console.log("formData", formData);

      // Call uploadDocumentAPI for each file
      await uploadDocumentAPI(formData, index, claimId);
    }

    setFiles([]);
    setDocumentsList([]);
    setRefreshClaimDetailsAPI(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // ON DELETE BUTTON CLICK
  const removeFile = (tempId) => {
    console.log("tempId", tempId);
    setFiles((files) => files.filter((file) => file.tempId !== tempId));
    setDocumentsList((previousFiles) =>
      previousFiles.filter((file) => file.tempId !== tempId)
    );
    // setFiles([]);
  };

  // CALLED WHEN IMAGE IS SELECTED
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles?.length) {
        const tempDateID = Date.now();
        setFiles((previousFiles) => [
          ...previousFiles,
          ...acceptedFiles.map((file) => ({
            file, // store the original file
            preview: URL.createObjectURL(file),
            name: documentName,
            filename: file.name,
            mime_type: getFileType(
              file.name.toLowerCase(),
              file.type.toLowerCase()
            ),
            filesize: formatFileSize(file.size),
            remarks: documentRemarks,
            uploaded_by: "",
            uploaded_at: "",
            tempId: tempDateID,
          })),
        ]);

        setDocumentsList((previousFiles) => [
          ...previousFiles,
          ...acceptedFiles.map((file) => {
            console.log("onDrop Selected file ", file);
            console.log(
              "onDrop Selected file documentRemarks ",
              documentRemarks
            );

            return {
              ...fileObject,
              name: documentName,
              filename: file.name,
              mime_type: getFileType(
                file.name.toLowerCase(),
                file.type.toLowerCase()
              ),
              filesize: formatFileSize(file.size),
              remarks: documentRemarks,
              uploaded_by: "",
              uploaded_at: "",
              tempId: tempDateID,
              upload: true,
            };
          }),
        ]);
      }
      if (rejectedFiles?.length) {
        displayAlert(
          "error",
          "Kindly check ur file size if it is less than 5 MB and format"
        );
      }
    },
    [documentName, documentRemarks, setFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
    // TODO need to remove in future
    maxFiles: 1,
    maxSize: 1024 * 5000,
    onDrop,
  });

  // UPLOAD DOCUMENTS API CALL
  const uploadDocumentAPI = async (formData, index, claimId) => {
    try {
      setBackdropOpen(true);
      console.log("uploadDocumentAPI: ", formData, index, claimId);
      let stage = "payout";
      const data = { formData, index, claimId, stage };
      const response = await dispatch(uploadDocumentDetails(data));
      const responsePayload = response.payload;
      console.log("uploadDocumentAPI response ", responsePayload);

      if (responsePayload.status === 200) {
        setBackdropOpen(false);
        console.log(
          "Document upload success for index ",
          responsePayload.index
        );
        displayAlert("success", MESSAGES.UPLOAD_SUCCESS);
      } else {
        setBackdropOpen(false);
        displayAlert("error", MESSAGES.UPLOAD_FAILED);
        console.log("Document upload failed for index ", responsePayload.index);
      }
    } catch (error) {
      setBackdropOpen(false);
      console.error("Error fetching data:", error);
      displayAlert("error", MESSAGES.UPLOAD_FAILED);
    }
  };

  if (
    params.claimId !== null &&
    params.claimId !== undefined &&
    !backdropOpen &&
    !claimDetailsLoading &&
    !userHasViewAccess
  ) {
    return <PageAccessDenied />;
  }

  if (
    params.claimId === null ||
    params.claimId === undefined ||
    (!claimDetailsLoading &&
      currentStage !== null &&
      (currentStage === MESSAGES.CLAIM_STAGE_FILING ||
        currentStage === MESSAGES.CLAIM_STAGE_UPLOAD_DOC ||
        currentStage === MESSAGES.CLAIM_STAGE_VERIFY_DOC ||
        currentStage === MESSAGES.CLAIM_STAGE_DECISION ||
        currentStage === MESSAGES.CLAIM_STAGE_COMMUNICATION))
  ) {
    return <ClaimNotReached />;
  }

  if (claimDetailsLoading) {
    return <CommonBackDrop backdropOpen={backdropOpen} />;
  }
  console.log(
    "userHasEditAccess ",
    userHasEditAccess,
    isCurrentStageInPayout,
    payoutDataExists,
    !editPayoutDetailsInProgress
  );
  if (!claimDetailsLoading) {
    return (
      <Box>
        {/* LOADER */}
        <CommonBackDrop backdropOpen={backdropOpen} />

        <Card id="claimantinfo--main__card" sx={{ m: 4, p: 4 }}>
          <Box display="flex">
            <Typography variant="h6" component="div">
              Payment Details
            </Typography>
            <Spacer value={1} />
            {userHasEditAccess &&
              isCurrentStageInPayout &&
              paymentDataExists &&
              !editPaymentDetailsInProgress && (
                <Tooltip title="Edit Payment Details">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(e) => {
                      setEditPaymentDetailsInProgress(true);
                      setPaymentDataExists(false);
                    }}
                  >
                    <Edit fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
          </Box>

          <Box sx={{ m: 2, p: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl
                  error={paymentModeError}
                  disabled={paymentDataExists ? true : false}
                  fullWidth
                >
                  <InputLabel id="claimantinfo--relationship__inputlabel">
                    Mode of Payout *
                  </InputLabel>
                  <Select
                    onChange={(e, key) => {
                      console.log("Payment Mode key", key.props);
                      const data = {
                        id: key.props.id,
                        name: key.props.children,
                        type: key.props.type,
                      };
                      console.log("Payment Mode", data);
                      setPayoutMode(data);
                    }}
                    labelId="claimantinfo--relationship__selectlabel"
                    id="claimantinfo--relationship__select"
                    name="payoutChannel"
                    label="Mode of Payout"
                    value={payoutMode.id}
                    helperText="Select Payment mode"
                  >
                    {paymentModes.map((item) => (
                      <MenuItem
                        name={item.name}
                        value={item.id}
                        id={item.id}
                        type={item.type}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {paymentModeError ? "Select Payment Mode" : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {(payoutMode.id === "bank_transfer" ||
                payoutMode.id === "demand_draft" ||
                payoutMode.id === "bank_cheque") && (
                <>
                  <Grid item xs={12} md={6} xl={4}>
                    <FormControl
                      disabled={paymentDataExists ? true : false}
                      fullWidth
                      error={Boolean(errors.bank_name)}
                    >
                      <InputLabel id="claimantinfo--relationship__inputlabel">
                        Bank Name *
                      </InputLabel>
                      <Select
                        labelId="claimantinfo--relationship__selectlabel"
                        id="claimantinfo--relationship__select"
                        label="Bank Name"
                        name="bank_name"
                        // value={values.bank_name}
                        // onChange={handleChange}
                        value={values.bank_name.id}
                        onChange={handleBankNameChange}
                        // error={Boolean(errors.bank_name)}
                      >
                        {paymentBanks.map((item) => (
                          <MenuItem
                            name={item.name}
                            value={item.id}
                            id={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{errors.bank_name}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} xl={4}>
                    <TextField
                      disabled={paymentDataExists ? true : false}
                      id="claimantinfo--primaryno__textfield"
                      label="Branch *"
                      variant="outlined"
                      fullWidth
                      name="branch_name"
                      value={values.branch_name}
                      onChange={handleChange}
                      error={Boolean(errors.branch_name)}
                      helperText={errors.branch_name}
                    />
                  </Grid>
                  {payoutMode.id === "bank_transfer" && (
                    <>
                      <Grid item xs={12} md={6} xl={4}>
                        <TextField
                          disabled={paymentDataExists ? true : false}
                          id="claimantinfo--primaryno__textfield"
                          label="Account No *"
                          variant="outlined"
                          fullWidth
                          name="bank_acc_no"
                          value={values.bank_acc_no}
                          onChange={handleChange}
                          error={Boolean(errors.bank_acc_no)}
                          helperText={errors.bank_acc_no}
                        />
                      </Grid>
                    </>
                  )}
                  {payoutMode.id === "demand_draft" && (
                    <>
                      <Grid item xs={12} md={6} xl={4}>
                        <TextField
                          disabled={paymentDataExists ? true : false}
                          id="claimantinfo--primaryno__textfield"
                          label="DD No *"
                          variant="outlined"
                          fullWidth
                          name="bank_dd_no"
                          value={values.bank_dd_no}
                          onChange={handleChange}
                          error={Boolean(errors.bank_dd_no)}
                          helperText={errors.bank_dd_no}
                        />
                      </Grid>
                    </>
                  )}
                  {payoutMode.id === "bank_cheque" && (
                    <>
                      <Grid item xs={12} md={6} xl={4}>
                        <TextField
                          disabled={paymentDataExists ? true : false}
                          id="claimantinfo--primaryno__textfield"
                          label="Cheque No *"
                          variant="outlined"
                          fullWidth
                          name="cheque_no"
                          value={values.cheque_no}
                          onChange={handleChange}
                          error={Boolean(errors.cheque_no)}
                          helperText={errors.cheque_no}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
              {payoutMode.id !== "none" &&
                payoutMode.id !== "" &&
                payoutMode.id !== undefined && (
                  <Grid item xs={12} md={6} xl={4}>
                    <TextField
                      disabled={paymentDataExists ? true : false}
                      id="claimantinfo--primaryno__textfield"
                      label={
                        payoutMode.type === "mobile_money"
                          ? "Holder/Receiver Name *"
                          : "Account Holder Name *"
                      }
                      variant="outlined"
                      fullWidth
                      name="acc_holder_name"
                      value={values.acc_holder_name}
                      onChange={handleChange}
                      error={Boolean(errors.acc_holder_name)}
                      helperText={errors.acc_holder_name}
                    />
                  </Grid>
                )}

              {payoutMode.id !== "none" &&
                payoutMode.id !== "" &&
                payoutMode.id !== undefined && (
                  <>
                    <Grid item xs={12} md={6} xl={4}>
                      <TextField
                        disabled={paymentDataExists ? true : false}
                        id="claimantinfo--primaryno__textfield"
                        label={
                          payoutMode.type === "mobile_money"
                            ? "Code/MSISDN *"
                            : "IFSC Code *"
                        }
                        variant="outlined"
                        fullWidth
                        name="bank_code"
                        value={values.bank_code}
                        onChange={handleChange}
                        error={Boolean(errors.bank_code)}
                        helperText={errors.bank_code}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                      <TextField
                        disabled={paymentDataExists ? true : false}
                        id="claimantinfo--primaryno__textfield"
                        label={
                          payoutMode.type === "mobile_money"
                            ? "City/Location"
                            : "City *"
                        }
                        variant="outlined"
                        fullWidth
                        name="branch_city"
                        value={values.branch_city}
                        onChange={handleChange}
                        error={Boolean(errors.branch_city)}
                        helperText={errors.branch_city}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
            {editPaymentDetailsInProgress && (
              <Grid container spacing={10} sx={{ pt: 4 }}>
                <Grid item xs={6} textAlign="right">
                  <Button
                    variant="outlined"
                    size="big"
                    sx={{ p: 1, m: 1, minWidth: 200 }}
                    onClick={handleClick("payment", "submit")}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    size="big"
                    sx={{ p: 1, m: 1, minWidth: 200 }}
                    onClick={(e) => {
                      setEditPaymentDetailsInProgress(false);
                      setPaymentDataExists(true);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Card>

        {/* Confirmation Dialog */}
        <UploadDocumentConfirmationDialog
          dialogOpen={openDialog}
          dialogAccept={handleDialogAccept}
          closeDialog={handleDialogClose}
        />

        {/* UPLOADED DOCUMENTS TABLE  */}
        {userHasListAccess &&
          documentsList !== null &&
          documentsList.length > 0 && (
            <>
              <Box sx={{ ml: 4, mr: 4 }}>
                <Typography variant="h6" component="div" sx={{ pb: 2 }}>
                  Payout Documents
                </Typography>
                <UploadDocumentTable
                  documentsList={documentsList}
                  handleShowMore={handleShowMore}
                  itemsToShow={itemsToShow}
                  removeFile={removeFile}
                  displayAlert={displayAlert}
                  stage={"payout"}
                />
              </Box>
            </>
          )}
        {files.length !== 0 && (
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              startIcon={<FileUpload />}
            >
              Upload Files
            </Button>
          </Grid>
        )}

        {payoutMode.id !== "none" && (
          <Card
            id="claimantinfo--main__card"
            sx={{ m: 4, pl: 4, pr: 4, pt: 3 }}
          >
            <Box display="flex">
              <Typography variant="h6" component="div">
                Payout Details
              </Typography>
              <Spacer value={1} />
              {userHasEditAccess &&
                isCurrentStageInPayout &&
                payoutDataExists &&
                !editPayoutDetailsInProgress && (
                  <Tooltip title="Edit Payout Details">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={(e) => {
                        setEditPayoutDetailsInProgress(true);
                        setPayoutDataExists(false);
                      }}
                    >
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
            </Box>
            <Box sx={{ m: 2, p: 2 }}>
              <Grid container spacing={4}>
                <>
                  <Grid item xs={12} md={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={payoutDataExists ? true : false}
                        disableFuture
                        format="dd/MM/yyyy"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(errors.payout_date),
                            helperText: errors.payout_date,
                          },
                        }}
                        label="Payout Date *"
                        value={
                          values.payout_date
                            ? parse(values.payout_date, dateFormat, new Date())
                            : null
                        }
                        onChange={(date) => handleDateChange(date)}
                        renderInput={(params) => (
                          <TextField {...params} name="payout_date" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <TextField
                      disabled={payoutDataExists ? true : false}
                      id="claimantinfo--primaryno__textfield"
                      label="Payout Amount *"
                      name="approved-payout"
                      variant="outlined"
                      fullWidth
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      error={Boolean(errors.amount)}
                      helperText={errors.amount}
                    />
                  </Grid>
                  {editPayoutDetailsInProgress && (
                    <Grid container spacing={10} sx={{ pt: 4 }}>
                      <Grid item xs={6} textAlign="right">
                        <Button
                          variant="outlined"
                          size="big"
                          sx={{ p: 1, m: 1, minWidth: 200 }}
                          onClick={handleClick("payout", "submit")}
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          size="big"
                          sx={{ p: 1, m: 1, minWidth: 200 }}
                          onClick={(e) => {
                            setEditPayoutDetailsInProgress(false);
                            setPayoutDataExists(true);

                            setDocumentName("");
                            setDocumentRemarks("");
                            setEnableAddDocumentsView(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {userHasWriteAccess &&
                    userHasImportAccess &&
                    !enableAddDocumentsView && (
                      <Grid textAlign="center" item xs={12} md={12} xl={12}>
                        <Button
                          disabled={payoutDataExists ? true : false}
                          sx={{ m: 0, p: 0, minWidth: 0, minHeight: 0 }}
                          onClick={(e) => {
                            setEnableAddDocumentsView(true);
                          }}
                        >
                          Add Payment/Payout Documents
                        </Button>
                      </Grid>
                    )}

                  {enableAddDocumentsView && (
                    <>
                      <Grid item xs={12} md={6} xl={6}>
                        <TextField
                          id="claimantinfo--location__textfield"
                          label="Document Name *"
                          name="document_name"
                          variant="outlined"
                          value={documentName}
                          disabled={payoutDataExists ? true : false}
                          onChange={(e) => {
                            setDocumentName(e.target.value);
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}>
                        <TextField
                          id="claimantinfo--location__textfield"
                          label="Remarks *"
                          name="document_name"
                          variant="outlined"
                          value={documentRemarks}
                          disabled={payoutDataExists ? true : false}
                          onChange={(e) => {
                            setDocumentRemarks(e.target.value);
                          }}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Card sx={{ backgroundColor: "#f7f7f7", p: 4 }}>
                          <div
                            {...getRootProps({
                              className: "dropzone",
                              ...(disableSelectFiles && {
                                style: { pointerEvents: "none", opacity: 0.5 },
                              }),
                            })}
                          >
                            <input
                              {...getInputProps()}
                              disabled={disableSelectFiles}
                            />
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <CloudUpload color="primary" />
                              <Spacer value={2} />
                              {isDragActive ? (
                                <Typography variant="body1">
                                  <strong>Drop the files here ...</strong>
                                </Typography>
                              ) : (
                                <Box display="flex">
                                  <Typography variant="body2">
                                    <strong>
                                      Drag & drop files here, or click to select
                                      files
                                    </strong>
                                  </Typography>
                                  <Spacer value={0.2} />
                                  <Typography variant="body2" color="primary">
                                    less than 5 MB
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </div>
                        </Card>
                      </Grid>
                    </>
                  )}
                </>
              </Grid>
            </Box>
          </Card>
        )}

        {/* )} */}

        {/* COMMUNICATION DETAILS */}
        {filteredMetaData && filteredMetaData.length > 0 && (
          <Box sx={{ m: 4 }}>
            <CommunicationDetailsCard
              enable={userHasEditAccess && isCurrentStageInPayout}
              title="Payout Additional Information"
              defaultMetaDataList={defaultMetaDataList}
              optionsList={filteredMetaData}
              onSubmit={handleCommunicationDetailsSubmit}
              onDataChange={handleDataChange}
            />
          </Box>
        )}

        {/* AGENT REMARKS */}
        <Box sx={{ m: 4 }}>
          <AgentRemarks
            agentRemarks={agentRemarks}
            setAgentRemarks={setAgentRemarks}
            buttonStatus={isCurrentStageInPayout ? false : true}
          />
        </Box>

        {/* NEXT AND PREVIOUS BUTTONS */}
        <Grid container spacing={4}>
          <Grid item xs={4} textAlign="right">
            <Button
              variant="contained"
              size="big"
              sx={{ p: 2, m: 4, minWidth: 200 }}
              // onClick={handleClick(4, "previous")}
              onClick={() => onNextButtonClick(4, "previous")}
              startIcon={<NavigateBefore />}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={4} textAlign="center">
            <Button
              variant="contained"
              size="big"
              sx={{ p: 2, m: 4, minWidth: 200 }}
              onClick={handleClick("all", "save")}
              disabled={
                currentStage === MESSAGES.CLAIM_STAGE_CLOSED ||
                currentStage === MESSAGES.CLAIM_STAGE_CLOSURE
                  ? true
                  : isCurrentStageInPayout && userHasWriteAccess
                  ? false
                  : true
              }
              startIcon={<Save />}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={4} textAlign="left">
            <Button
              variant="contained"
              size="big"
              disabled={
                currentStage === MESSAGES.CLAIM_STAGE_CLOSED ||
                currentStage === MESSAGES.CLAIM_STAGE_CLOSURE
                  ? true
                  : isCurrentStageInPayout && userHasWriteAccess
                  ? false
                  : true
              }
              sx={{ p: 2, m: 4, minWidth: 200 }}
              onClick={handleClick("all", "next")}
              endIcon={<NavigateNextIcon />}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
};

export default ClaimsPayout;
