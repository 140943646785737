import { Box, Typography } from '@mui/material';
import React from 'react'
import styled from 'styled-components';

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey.dark
  }));

function TitleDesc({title, desc}) {
    return (
        <Box id={`${title}_box`} sx={{p:1}} >
            <StyledTypography variant="subtitle1" color="text.secondary">{title}</StyledTypography>
            <Typography style={{ wordWrap: 'break-word' }} variant="subtitle2" gutterBottom >{desc}</Typography>
        </Box>
    )
}

export default TitleDesc;