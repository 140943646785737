import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Radio,
  Slide,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Suspense, useState } from "react";
import { useDispatch } from "react-redux";
//import BeneficiaryDetails from "../beneficiary-details/beneficiary-details.component";
import { setIncidentDateForSubData, setSelectedSubscription } from "store/subscriptions/subscriptions-slice";
import { FormatDateUtil } from "utils/formatdate";
import CoverHistory from "../cover-history/cover-history.components";

const BeneficiaryDetails = React.lazy(() =>
  import("../beneficiary-details/beneficiary-details.component")
);
const PaymentsHistory = React.lazy(() =>
  import("../payments-history/payments-history.components")
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SubscriptionDetails = ({
  msisdn,
  dateOfIncident,
  subscriptionDetails,
  customerPlatform,
  currentCustomerId,
  subscriptionIdChangeHandler,
}) => {
  const [openPaymentsHistory, setOpenPaymentsHistory] = useState(false);
  const [openCoverHistory, setOpenCoverHistory] = useState(false);
  const [openBeneficiary, setOpenBeneficiary] = useState(false);
  const [radioButtonValue, setRadioButtonValue] = useState("");

  const [beneficiaryRequestObject, setBeneficiaryRequestObject] = useState({});
  const [paymentHistoryRequestObject, setPaymentHistoryRequestObject] =
    useState({});
    const [coverHistoryRequestObject, setCoverHistoryRequestObject] =
    useState({});

  const dispatch = useDispatch();

  const handleBeneficiaryOpen = (subscriptionItem) => {
    setBeneficiaryRequestObject({
      subscription_id: subscriptionItem.subscription_id,
      platform_name: subscriptionItem.platform_name,
      incident_date: FormatDateUtil.init(dateOfIncident),
    });
    setOpenBeneficiary(true);
  };

  const handleBeneficiaryClose = () => {
    setOpenBeneficiary(false);
  };

  const handlePaymentsHistoryOpen = (subscriptionItem) => {
    setPaymentHistoryRequestObject({
      subscription_id: subscriptionItem.subscription_id,
      policy_id: subscriptionItem.product_id,
      platform_name: subscriptionItem.platform_name,
      incident_date: FormatDateUtil.init(dateOfIncident),
    });
    setOpenPaymentsHistory(true);
  };

  const handleCoverHistoryOpen = (subscriptionItem) => {
    console.log("Cover History Clicked");
    console.log(subscriptionItem.cover_start_date);
    setCoverHistoryRequestObject({
      subscription_id: subscriptionItem.subscription_id,
      policy_id: subscriptionItem.product_id,
      platform_name: subscriptionItem.platform_name,
      incident_date: FormatDateUtil.init(dateOfIncident),
    });
    setOpenCoverHistory(true);
  }

  const handlePaymentsHistoryClose = () => {
    setOpenPaymentsHistory(false);
  };

  const handleCoverHistoryClose = () => {
    setOpenCoverHistory(false);
  };

  const handleRadioButtonChange = (event) => {
    var selectedSubscription = subscriptionDetails.filter((obj) => {
      return obj.subscription_id === event.target.value;
    });
    dispatch(setSelectedSubscription(selectedSubscription[0]));
    dispatch(setIncidentDateForSubData(dateOfIncident));
    subscriptionIdChangeHandler(selectedSubscription[0].subscription_id);
    setRadioButtonValue(event.target.value);
  };

  return (
    <>
      {subscriptionDetails && (
        <Box sx={{ pt: 2, px: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color="primary">
                SUBSCRIPTION DETAILS
              </Typography>
            </Grid>
          </Grid>
          <TableContainer sx={{ pt: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">Policy ID</TableCell>
                  <TableCell align="left">Customer ID</TableCell>
                  <TableCell align="left">Policy Name</TableCell>
                  <TableCell align="left">Policy Status</TableCell>
                  <TableCell align="left">Coverage Start Date</TableCell>
                  <TableCell align="left">Cover Month</TableCell>
                  <TableCell align="left">Cover Amount</TableCell>
                  <TableCell align="left">Platform Name</TableCell>
                  <TableCell align="left">Beneficiary List</TableCell>
                  <TableCell align="left">Payment History</TableCell>
                  <TableCell align="left">Cover History</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptionDetails &&
                  subscriptionDetails.map((subscriptionItem) => (
                    <TableRow
                      key={subscriptionItem.product_name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Radio
                          checked={
                            customerPlatform ===
                              subscriptionItem.platform_name &&
                            radioButtonValue ===
                              `${subscriptionItem.subscription_id}`
                          }
                          onChange={handleRadioButtonChange}
                          value={`${subscriptionItem.subscription_id}`}
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                          disabled={
                            ((customerPlatform === subscriptionItem.platform_name && currentCustomerId === subscriptionItem.customer_id) 
                              ? false
                              : true
                            )
                          }
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {subscriptionItem.subscription_id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {subscriptionItem.customer_id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {`${subscriptionItem.product_name}-${subscriptionItem.variant_name}`}
                      </TableCell>
                      <TableCell align="left">{subscriptionItem.incident_policy_status}</TableCell>
                      <TableCell align="left">{(subscriptionItem.cover_start_date === "" || subscriptionItem.cover_start_date === null) ? "NA" : subscriptionItem.cover_start_date  }</TableCell>
                      <TableCell align="left">{FormatDateUtil.displayMonth(dateOfIncident)}</TableCell>
                      <TableCell align="left">
                        {subscriptionItem?.cover_provided_incident_month ? parseFloat(subscriptionItem?.cover_provided_incident_month).toFixed(2) : "NA"}
                      </TableCell>
                      <TableCell align="left">
                        {subscriptionItem.platform_name}
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          variant="text"
                          size="small"
                          sx={{ textTransform: "none" }}
                          onClick={() =>
                            handleBeneficiaryOpen(subscriptionItem)
                          }
                        >
                          VIEW
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          variant="text"
                          size="small"
                          sx={{ textTransform: "none" }}
                          onClick={() =>
                            handlePaymentsHistoryOpen(subscriptionItem)
                          }
                        >
                          VIEW
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          variant="text"
                          size="small"
                          sx={{ textTransform: "none" }}
                          onClick={() =>
                            handleCoverHistoryOpen(subscriptionItem)
                          }
                        >
                          VIEW
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {Object.keys(beneficiaryRequestObject).length !== 0 &&
            <Dialog
              open={openBeneficiary}
              TransitionComponent={Transition}
              keepMounted
              fullWidth={true}
              maxWidth={"lg"}
              onClose={handleBeneficiaryClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"List of Beneficiaries"}</DialogTitle>
              <DialogContent>
                <Suspense fallback={<div>Loading...</div>}>
                  <BeneficiaryDetails
                    {...beneficiaryRequestObject}
                  />
                </Suspense>
              </DialogContent>
            </Dialog>
            }
          {Object.keys(paymentHistoryRequestObject).length !== 0 &&
            <Dialog
              open={openPaymentsHistory}
              TransitionComponent={Transition}
              keepMounted
              fullWidth={true}
              maxWidth={"lg"}
              onClose={handlePaymentsHistoryClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Payment History"}</DialogTitle>
              <DialogContent>
                <Suspense fallback={<div>Loading...</div>}>
                  <PaymentsHistory
                    {...paymentHistoryRequestObject}
                  />
                </Suspense>
              </DialogContent>
            </Dialog>
            }
            {Object.keys(coverHistoryRequestObject).length !== 0 &&
              <Dialog
                open={openCoverHistory}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={"lg"}
                onClose={handleCoverHistoryClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>{"Cover History"}</DialogTitle>
                <DialogContent>
                  <Suspense fallback={<div>Loading...</div>}>
                    <CoverHistory
                      {...coverHistoryRequestObject}
                    />
                  </Suspense>
                </DialogContent>
              </Dialog>
            }
        </Box>
      )}
    </>
  );
};

export default SubscriptionDetails;
