import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { presentClaimStage } from "store/claim-details/claim-details-selectors";
import Spacer from "ui/components/utils/spacer";

const AgentRemarks = ({
  agentRemarks,
  setAgentRemarks,
  buttonStatus,
  stageName,
  recommendation,
  addNotesTemplates,
}) => {
  const currentClaimStageData = useSelector(presentClaimStage);
  const [template, setTemplate] = React.useState("");
  const [filteredTemplate, setFilteredTemplate] = React.useState([]);

  useEffect(() => {
    if (
      recommendation &&
      recommendation?.id !== "" &&
      addNotesTemplates !== null
    ) {
      var filteredData = addNotesTemplates.filter(filterListByRecommendation);
      setFilteredTemplate(filteredData);
    }
  }, [recommendation]);

  function filterListByRecommendation(item) {
    return item.filter.includes(recommendation?.id);
  }

  const handleChange = (event) => {
    setTemplate(event);
    setAgentRemarks((agentRemarks) => {
      const description = event && event.description ? event.description : "";
      return (agentRemarks + (description ? `\n${description}. ` : "")).trim();
    });
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Card id="claimantinfo--main__card" sx={{ p: 2 }}>
        <Typography variant="h6">Additional Notes</Typography>
        <Spacer value={2} />
        {stageName === "verification" && addNotesTemplates !== null && (
          <Grid container spacing={2}>
            <Grid item xs={12} mx={4} mt={2}>
              <Autocomplete
                disablePortal
                name="selectTemplateVerification"
                value={template}
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                options={
                  filteredTemplate.length > 0
                    ? filteredTemplate
                    : addNotesTemplates
                }
                getOptionLabel={(option) =>
                  typeof option.name === "string" ||
                  option.name instanceof String
                    ? option.name
                    : ""
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                    label="Select Template"
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        <Box sx={{ m: 2, p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="agentnotes__textfield"
                label="Additional Notes"
                name="agentRemark"
                multiline
                rows={4}
                disabled={
                  currentClaimStageData === "Closure" ||
                  currentClaimStageData === "Closed"
                    ? true
                    : buttonStatus
                }
                fullWidth
                value={agentRemarks}
                onChange={(e) => setAgentRemarks(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default AgentRemarks;
